import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const JournalDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const [entry, setEntry] = useState(null);

  useEffect(() => {
    // Get the detailFile from the location state or construct it
    const detailFile = location.state?.detailFile || `entry${id}.json`;

    fetch(`/data/${detailFile}`)
      .then((response) => response.json())
      .then((data) => setEntry(data))
      .catch((error) => console.error("Error fetching journal entry:", error));
  }, [id, location.state]);

  if (!entry) {
    return <Typography>Loading...</Typography>;
  }

  const sanitizedContent = DOMPurify.sanitize(entry.content);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {entry.title}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {new Date(entry.date).toLocaleDateString()}
      </Typography>
      <Box
        sx={{ my: 2 }}
        variant="body1"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
      <Grid container spacing={2}>
        {entry.photos.map((photo, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image="https://u.pcloud.link/publink/show?code=XZvslv0ZfTxV6b4VR00YpH0cBXnx6mOc71QV"
                alt={`Photo ${index + 1}`}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default JournalDetail;
